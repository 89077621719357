import { useState, useRef, useEffect } from 'react'
import { supportsMotion } from '../Utilities'

/**
 * Uses accelerometer motion to detemine if we're in
 * an elevator that goes up or down.
 */
export function useElevatorDirection({
  onChange,
  calibration = 0,
  debug = false,
}) {
  let [direction, setDirection] = useState('down')
  let [detectMotion, setDetectMotion] = useState(false)
  let [stats, setStats] = useState({ average: 0, median: 0, sum: 0 })

  // Device motion needs to be started on user input, at lest for iOS.
  function startMotionUpdates() {
    if (!supportsMotion()) return

    if (typeof DeviceMotionEvent?.requestPermission === 'function') {
      DeviceMotionEvent.requestPermission()
        .then(response => {
          if (response === 'granted') {
            setDetectMotion(true)
          }
        })
        .catch(console.error)
    } else {
      setDetectMotion(true)
    }
  }

  // Switch between welcome page and selection page using
  // the accelerometer.
  let history = useRef([])
  useEffect(() => {
    if (!detectMotion) return

    function handleMotion(e) {
      // Number of entries for the measured duration of seconds. Assuming 60 Hz.
      const count = 60 * 6
      // These are proper thresholds.
      // const averageThreshold = 0.2
      // const medianThreshold = 0.12

      // These are thresholds for the Galaxy Tab A,
      // with one decimal resolution. It has no gyroscope
      // so we have to manually subtract gravity.
      const averageThreshold = 0.22
      const medianThreshold = 0.08
      // Weird gravity on Galaxy Tab A, y axis in perfect
      // portrait mode.
      const gravity = 9.5

      // Calibration is the value at rest. -0.04 on Johans iPhone.
      let value = e.accelerationIncludingGravity.y - calibration - gravity
      // Don't count the value unless we're standing up in portrait. This is only
      // because we manually have to subtract gravity on Galaxy Tab A and
      // we're only doing that for perfect portrait mode. This makes it so that
      // the tablet can lay flat down (for testing, demoing) without registering an acceleration.
      if (
        Math.abs(e.accelerationIncludingGravity.x) > 2 ||
        Math.abs(e.accelerationIncludingGravity.z) > 2
      ) {
        value = 0
      }

      // If the device has a gyroscope and can subtract gravity, we
      // should use this instead.
      // let value = e.acceleration.y - calibration

      history.current = history.current.concat(value).slice(-count)

      // We'll only measure after we have enough data.
      if (history.current.length !== count) return

      let sum = history.current.reduce((acc, curr) => acc + curr)
      let average = sum / count
      let median = history.current.slice().sort((a, b) => a - b)[
        Math.floor(count * 0.5)
      ]

      // We'll only save the stats if we're debugging to prevent
      // unnecessary rerenders.
      if (debug) {
        setStats({ average, sum, median, value })
        return
      }

      if (
        average < -averageThreshold &&
        median < -medianThreshold &&
        direction !== 'down'
      ) {
        // The acceleration is negative, which means:
        // We're at the bottom on iOS.
        // We're at the top on Android.
        history.current = []
        // Assume Android for now.
        setDirection('down')
        onChange('down')
      } else if (
        average > averageThreshold &&
        median > medianThreshold &&
        direction !== 'up'
      ) {
        // The acceleration is positive, which means:
        // We're at the top on iOS.
        // We're at the bottom on Android.
        history.current = []
        // Assume Android for now.
        setDirection('up')
        onChange('up')
      }
    }

    window.addEventListener('devicemotion', handleMotion)

    return () => window.removeEventListener('devicemotion', handleMotion)
  }, [detectMotion, direction, onChange, calibration, debug])

  // Switch between welcome page and selection page with arrow keys.
  useEffect(() => {
    function handleKeyPress(e) {
      switch (e.keyCode) {
        case 38:
          setDirection('up')
          onChange('up')
          break
        case 40:
          setDirection('down')
          break
        default:
      }
    }

    document.addEventListener('keydown', handleKeyPress)

    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [onChange])

  return {
    direction,
    startMotionUpdates:
      !detectMotion && supportsMotion() ? startMotionUpdates : null,
    stats,
    toggleDirection: () => {
      let d = direction === 'up' ? 'down' : 'up'
      setDirection(d)
      onChange(d)
    },
  }
}
