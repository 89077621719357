import React, { useEffect, useState } from 'react'
import { GidasBar } from '../Components/GidasBar'
import { useFetch } from '../Hooks'
import { Plate } from '../Components/Plate'
import { round, SALAD_AND_BREAD_CO2 } from '../Utilities'
import {
  Battery,
  ChefHat,
  Diesel,
  Farm,
  Fish,
  Fork,
  Knife,
  Oats,
  Pig,
  Road,
  Tractor,
  Truck,
} from '../img/icons'
import arctura from '../img/arctura.png'
import { ChevronRight, Plus, Tablet } from 'react-feather'
import { motion } from 'framer-motion'
import { PieChart } from '../Components/PieChart'

function totalCO2(resources, category) {
  return resources
    .filter(x => (category ? x.category === category : true))
    .map(x => x.co2 * (x.pivot.weight ?? x.portion_weight))
    .reduce((prev, curr) => prev + curr, 0)
}

/**
 * The Menu shows the current menu items, along with information
 * about their carbon impact.
 */
export function Menu() {
  // The dishes are loaded from the server, and are refreshed periodically.
  let { data } = useFetch('/dishes', { refresh: 1000 * 60 * 10 })

  // Scale all rem values according to aspect screen width.
  useEffect(() => {
    document.documentElement.classList.add('text-aspect-ratio')

    return () => document.documentElement.classList.remove('text-aspect-ratio')
  }, [])

  // Read the width of the aspect ratio container.
  let [width, setWidth] = useState(0)

  useEffect(() => {
    const listener = () => {
      setWidth(
        document.getElementsByClassName('aspect-ratio')[0]?.offsetWidth ?? 0,
      )
    }

    window.addEventListener('resize', listener)
    listener()

    return () => window.removeEventListener('resize', listener)
  }, [setWidth])

  if (!data)
    return (
      <div className="h-full w-full flex justify-center items-center bg-black">
        <div className="aspect-ratio bg-orange-100" />
      </div>
    )

  let dishes = data

  // let resources = uniqueBy(
  //   dishes.flatMap(x => x.resources),
  //   x => x.id,
  // )

  // Here we'll calculate the sum of co2 for all dishes,
  // as well as the percentage of carbs, protein and salad.
  // let stats = dishes.reduce(
  //   (previous, dish) => {
  //     let co2 = totalCO2(dish.resources)
  //     let carbs = totalCO2(dish.resources, 'carbs')
  //     let protein = totalCO2(dish.resources, 'protein')
  //     let rest = co2 - carbs - protein
  //     let salad = SALAD_AND_BREAD_CO2

  //     return {
  //       carbs: previous.carbs + carbs / co2,
  //       protein: previous.protein + protein / co2,
  //       rest: previous.rest + rest / co2,
  //       salad: salad / co2,
  //     }
  //   },
  //   {
  //     carbs: 0,
  //     protein: 0,
  //     salad: 0,
  //     rest: 0,
  //   },
  // )

  // let carbs = stats.carbs / (dishes.length || 1)
  // let protein = stats.protein / (dishes.length || 1)
  // let salad = stats.salad / (dishes.length || 1)
  // let rest = stats.rest / (dishes.length || 1)

  return (
    <div className="h-full w-full flex justify-center items-center bg-black">
      <div className="aspect-ratio bg-orange-100">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="h-full flex flex-col"
        >
          <h1 className="font-overpass font-bold mx-4 mt-6 text-2xl -text-white text-gray-900">
            Tallriksmodellen 2.0
          </h1>
          <h2 className="font-overpass mx-4 mb-2 text-xl text-gray-900">
            Här visas <span className="font-bold">klimatavtrycket</span> för
            dagens råvaror
          </h2>
          <div className="flex space-x-4 mx-4">
            {dishes.map(dish => {
              let carbResources = dish.resources.filter(
                x => x.category === 'carbs',
              )
              let proteinResources = dish.resources.filter(
                x => x.category === 'protein',
              )

              let co2 = totalCO2(dish.resources)
              let carbs = totalCO2(carbResources)
              let protein = totalCO2(proteinResources)

              let rest = co2 - carbs - protein
              let salad = SALAD_AND_BREAD_CO2

              // Since we're using a constand salad value, we have to
              // add it to the total in order to get correct percentages.
              co2 += SALAD_AND_BREAD_CO2

              let slices = [
                {
                  percent: protein / co2,
                  color: '#7b4096',
                  label: proteinResources.map(x => x.name).join(', '),
                },
                {
                  percent: carbs / co2,
                  color: '#f9907b',
                  label: carbResources.map(x => x.name).join(', '),
                },
                {
                  percent: rest / co2,
                  color: '#5b9db5',
                  label: 'Övriga råvaror',
                },
                {
                  percent: salad / co2,
                  color: '#55b186',
                  label: 'Salladsbuffé',
                },
              ]

              return (
                <div
                  key={dish.id}
                  className="flex-1 rounded p-3 shadow bg-gradient-to-b from-gray-700 to-gray-900"
                >
                  <p className="font-semibold mb-4 -text-gray-800 text-sm text-white">
                    {dish.text}
                  </p>
                  <div className="flex justify-between items-center">
                    <div className="flex-1 mr-4 space-y-2 bg-white text-gray-800 p-2 shadow">
                      <p className="text-sm">Klimatavtryck</p>
                      {slices.map((slice, i) => (
                        <div key={i} className="flex items-center">
                          <div
                            className="rounded-full h-4 w-4 mr-2"
                            style={{ backgroundColor: slice.color }}
                          />
                          <div className="leading-none">
                            <p className="text-xs font-bold mb-1">
                              {slice.label}
                            </p>
                            <p className="text-xs">
                              {round(slice.percent * 100, 1)} %
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="flex items-center justify-center">
                      <div className="rounded-full bg-white w-48 p-3">
                        {/* <Plate percent={slices} /> */}
                        <PieChart
                          data={{
                            labels: slices.map(x => x.label),
                            datasets: [
                              {
                                borderWidth: width * 0.002,
                                data: slices.map(x => x.percent),
                                backgroundColor: slices.map(x => x.color),
                              },
                            ],
                          }}
                        />
                      </div>
                      {/* <div style={{ width: '1.5rem' }}>
                      <Fork className="text-white drop-shadow-sm w-full h-full" />
                    </div>
                    <div className="w-5">
                      <Knife className="text-white drop-shadow-sm w-full h-full" />
                    </div> */}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <p className="mt-2 text-xs mx-4">
            Beräkningarna baseras på en standardportion.
          </p>
          <div className="mt-auto"></div>
          <GidasBar />
        </motion.div>
      </div>
    </div>
  )
}

// eslint-disable-next-line
function OldMenu({ dishes }) {
  return (
    <div className="h-full flex flex-col bg-orange-100- bg-gradient-to-br from-orange-100 to-pink-100">
      <div className="p-4 text-white">
        <div className="_max-w-6xl _mx-auto mx-32">
          <h1 className="text-4xl font-overpass mt-12 mb-12">
            <span className="decoration-break-clone bg-gray-800 bg-opacity-75 px-2 py-2 leading-loose">
              Lär dig hur <span className="font-bold">dagens råvaror</span>{' '}
              påverkar <span className="font-bold">klimatet</span>!<br />
              Så här har vi räknat.
            </span>
            {/* <span className="decoration-break-clone bg-gray-800 bg-opacity-75 px-2 leading-8">
              <span className="font-bold">Råvarorna på din tallrik</span>{' '}
              påverkar klimatet olika mycket och vi kallar fördelningen för{' '}
              <span className="font-bold">Tallriksmodellen 2.0</span>
            </span> */}
          </h1>
        </div>
        <div className="flex flex-col md:flex-row justify-around mx-32 _items-start md:space-x-8">
          {/* Column 1 */}
          <div className="flex-1 text-white flex flex-col min-w-0 md:flex-basis-side-col">
            <p className="mb-4 text-white leading-relaxed text-2xl text-shadow font-bold whitespace-no-wrap">
              Lunchens klimatavtryck beräknas så här
            </p>
            <div className="bg-gidas-dark p-6 flex-1 flex flex-col justify-between rounded-lg shadow">
              <div className="rounded-lg p-3 shadow-inner bg-gidas-dark-light">
                <p className="uppercase font-semibold">Jordbruk</p>
                <div className="flex">
                  <div className="flex-1 flex flex-col items-center justify-around">
                    <Tractor className="w-10 h-10" />
                    <Diesel className="w-10 h-10" />
                  </div>
                  <Farm className="w-24 h-24" />
                  <div className="flex-1 flex flex-col items-center justify-around">
                    <div className="flex items-center justify-around self-stretch">
                      <Pig className="w-10 h-10" />
                      <Fish className="w-10 h-10" />
                    </div>
                    <Oats className="w-10 h-10" />
                  </div>
                </div>
              </div>
              <div className="flex justify-center py-2">
                <Plus className="w-12 h-12 drop-shadow" />
              </div>
              <div className="rounded-lg p-3 shadow-inner bg-gidas-dark-light">
                <p className="uppercase font-overpass">Transport</p>
                <div className="flex items-center justify-around">
                  <Diesel className="w-10 h-10" />
                  <Truck className="w-24 h-24" />
                  <Road className="w-10 h-10" />
                </div>
              </div>
              <div className="flex justify-center py-2">
                <Plus className="w-12 h-12 drop-shadow" />
              </div>
              <div className="rounded-lg p-3 shadow-inner bg-gidas-dark-light">
                <p className="uppercase font-overpass">Restaurang</p>
                <div className="flex items-center justify-around">
                  <Battery className="w-10 h-10" />
                  <img
                    alt="Arcturas logo"
                    src={arctura}
                    className="h-24"
                    // style={{
                    //   // Converts white png color to bg-gidas-dark: https://codepen.io/sosuke/pen/Pjoqqp
                    //   filter:
                    //     'brightness(0) saturate(100%) invert(22%) sepia(24%) saturate(3461%) hue-rotate(165deg) brightness(91%) contrast(106%)',
                    // }}
                  />
                  <ChefHat className="w-10 h-10" />
                </div>
              </div>
            </div>
          </div>

          <ChevronRight className="w-16 h-16 drop-shadow self-center" />

          {/* Column 2 */}
          <div className="flex-1 text-white flex flex-col md:flex-basis-middle-col">
            <p className="mb-4 text-white leading-relaxed text-2xl text-shadow font-bold">
              Fördelning av klimatavtrycket för dagens lunch
            </p>
            <div className="bg-gidas-dark_ space-y-6 flex-1 flex flex-col justify-center">
              {/* <p className="text-xl bg-orange-100 text-gray-900 p-4 shadow mb-8">
              Så här är miljöpåverkan fördelad över råvarorna på dagens meny
            </p> */}
              {/* <h3>Så här ser fördelningen på dagens meny ut</h3> */}
              {dishes.map(dish => {
                let carbResources = dish.resources.filter(
                  x => x.category === 'carbs',
                )
                let proteinResources = dish.resources.filter(
                  x => x.category === 'protein',
                )

                let co2 = totalCO2(dish.resources)
                let carbs = totalCO2(carbResources)
                let protein = totalCO2(proteinResources)

                let rest = co2 - carbs - protein
                let salad = SALAD_AND_BREAD_CO2

                let slices = [
                  {
                    percent: protein / co2,
                    color: '#7b4096',
                    label: proteinResources.map(x => x.name).join(', '),
                  },
                  {
                    percent: carbs / co2,
                    color: '#f9907b',
                    label: carbResources.map(x => x.name).join(', '),
                  },
                  {
                    percent: salad / co2,
                    color: '#55b186',
                    label: 'Sallad',
                  },
                  {
                    percent: rest / co2,
                    color: '#5b9db5',
                    label: 'Övrigt',
                  },
                ]

                return (
                  <div
                    key={dish.id}
                    className="rounded-lg border border-white border-opacity-75 p-3 shadow bg-orange-100"
                  >
                    <p className="font-semibold text-xl mb-4 text-gray-800">
                      {dish.text}
                    </p>
                    <div className="flex justify-around items-center">
                      <div className="space-y-2 bg-white text-gray-800 mr-8 p-2 shadow">
                        <p className="font-overpass text-lg">
                          Koldioxidpåverkan
                        </p>
                        {slices.map((slice, i) => (
                          <div key={i} className="flex items-center">
                            <div
                              className="rounded-full h-4 w-4 mr-2"
                              style={{ backgroundColor: slice.color }}
                            />
                            <p>{slice.label}</p>
                          </div>
                        ))}
                      </div>
                      <div className="flex items-center justify-center">
                        <Fork className="text-gray-700 drop-shadow-sm" />
                        <div
                          className="mx-2 text-gray-400"
                          style={{ width: '14rem' }}
                        >
                          <Plate percent={slices} />
                        </div>
                        <Knife className="text-gray-700 drop-shadow-sm" />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <ChevronRight className="w-16 h-16 drop-shadow self-center" />

          {/* Column 3 */}
          <div className="flex-1  text-white flex flex-col min-w-0 md:flex-basis-side-col">
            <p className="mb-4 text-white leading-relaxed text-2xl text-shadow font-bold whitespace-no-wrap">
              Räkna på dig själv - besök skärmen i hissen
            </p>
            <div className="flex-1 bg-gidas-dark p-4 flex flex-col justify-center rounded-lg shadow">
              <p className="text-2xl mb-4 text-center font-semibold">
                Slängde du mat idag?
              </p>
              <div className="flex justify-center mb-4 drop-shadow">
                <Tablet className="w-32 h-32" />
              </div>
              <p className="text-2xl text-center font-semibold">
                Beräkna ditt klimatavtryck
                <br />
                på vägen ner.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-auto"></div>
      <GidasBar wide />
    </div>
  )
}
