import React from 'react'
import ReactDOM from 'react-dom'
import './generated/index.css'
import * as serviceWorker from './serviceWorker'
import { Router } from '@reach/router'
import Kiosk from './Pages/Kiosk'
import { Menu } from './Pages/Menu'
import App from './App'

// Preload all images.
import arctura from './img/arctura.png'
import gri12 from './img/gri/12.jpg'
import gri13 from './img/gri/13.jpg'
import gri15 from './img/gri/15.jpg'
import logo from './img/logo.png'
import dille from './img/dille.png'
import { preloadImages } from './Utilities'

preloadImages([arctura, gri12, gri13, gri15, logo, dille])

ReactDOM.render(
  <React.StrictMode>
    <Router className="h-full">
      <App path="/" />
      <Kiosk path="/kiosk" />
      <Menu path="/menu" />
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
