export function preloadImages(images) {
  images.forEach(src => (new Image().src = src))
}

export function randomProperty(obj) {
  var keys = Object.keys(obj)
  return obj[keys[(keys.length * Math.random()) << 0]]
}

export function randomElement(array) {
  return array[Math.floor(Math.random() * array.length)]
}

export function round(value, digits) {
  return (
    Math.round((value + Number.EPSILON) * Math.pow(10, digits)) /
    Math.pow(10, digits)
  )
}

export function supportsMotion() {
  return 'DeviceMotionEvent' in window
}

export function api(url, options = {}) {
  return fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
    ...options,
  })
}

export async function post(url, data) {
  return api(url, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

let wasteExamples = [
  {
    name: 'Diesel',
    factor: 0.185,
    text(co2) {
      return `${this.value(co2)} km i dieselbil`
    },
    value(co2) {
      return round(co2 / this.factor, 2)
    },
  },
  // {
  //   name: 'Bensin',
  //   factor: 0.268,
  //   text(co2) {
  //     return `${round(co2 / this.factor, 2)} km i bensinbil`
  //   },
  // },
  // {
  //   name: 'Potatis',
  //   factor: 0.1,
  //   text(co2) {
  //     return `${round(co2 / this.factor, 2)} kg potatis`
  //   },
  // },
]

export let diesel = wasteExamples[0]

export function uniqueBy(values, callback) {
  let found = new Set()
  let unique = []

  for (let value of values) {
    let id = callback(value)
    if (found.has(id)) {
      continue
    }
    found.add(id)
    unique.push(value)
  }

  return unique
}

export const SALAD_AND_BREAD_CO2 = 0.122
