import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'

import {
  Chart as ChartJS,
  LineController,
  PieController,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarController,
  LineElement,
  PointElement,
  ArcElement,
} from 'chart.js'

ChartJS.register(
  LineController,
  BarController,
  PieController,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Filler,
  Legend,
  LineElement,
  PointElement,
  ArcElement,
)

export function PieChart({ className = '', data }) {
  const container = useRef(null)
  const chart = useRef(null)

  useEffect(() => {
    chart.current = new ChartJS(container.current, {
      type: 'pie',
      options: {
        legend: {
          display: false,
        },
        maintainAspectRatio: true,
      },
      data,
    })
    return () => chart.current.destroy()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    chart.current.data.datasets = data.datasets
    chart.current.update('none')
  }, [data.datasets])

  return (
    <div className={classNames(className, '-relative')}>
      <canvas ref={container} />
    </div>
  )
}
