import React from 'react'
import { Link } from '@reach/router'

/**
 * The App component serves as the starting point and provides
 * links to navigate to the other screens.
 */
function App() {
  return (
    <div className="bg-gidas-light h-full flex flex-col justify-center">
      <h1 className="font-overpass font-bold text-5xl text-orange-100 mb-2 text-center">
        Arctura Sustainability Hub
      </h1>
      <h2 className="font-overpass font-bold text-4xl text-orange-100 mb-16 text-center">
        Digital kiosk
      </h2>
      <div className="flex items-center justify-center">
        <Link
          className="font-overpass text-3xl text-gray-800 px-8 py-4 bg-gray-300 hover:bg-gray-400 rounded shadow mx-4"
          to="/kiosk"
        >
          Kiosk
        </Link>
        <Link
          className="font-overpass text-3xl text-gray-800 px-8 py-4 bg-gray-300 hover:bg-gray-400 rounded shadow mx-4"
          to="/menu"
        >
          Meny
        </Link>
      </div>
    </div>
  )
}

export default App
