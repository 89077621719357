import React, { useState, useMemo } from 'react'
import { AnimateSharedLayout, motion } from 'framer-motion'
import {
  round,
  diesel,
  SALAD_AND_BREAD_CO2 as SALAD_AND_BREAD_CO2_PER_PORTION,
} from '../Utilities'
import { useFetch, useSyncedEntries } from '../Hooks'
import gri12 from '../img/gri/12.jpg'
import gri13 from '../img/gri/13.jpg'
import gri15 from '../img/gri/15.jpg'
import logo from '../img/logo.png'
import { useElevatorDirection } from '../Hooks/useElevatorDirection'
import { Plate } from '../Components/Plate'
import { parse } from 'query-string'
import { Fork, Knife } from '../img/icons'

const spring = {
  type: 'spring',
  stiffness: 80,
  damping: 15,
}

/**
 * The Kiosk allows the user to enter feedback about how much
 * leftovers they had today. We'll calculate and display the
 * carbon impact.
 */
function Kiosk({ location }) {
  // We'll show debug info using the query param ?debug.
  let query = parse(location.search)
  let debug = query.debug !== undefined

  // Let the user calibrate the accelerometer by passing a query param.
  let calibration = parseFloat(query.calibration) || 0

  // The dishes are loaded from the server, and are refreshed periodically,
  // or on network connection.
  let { data } = useFetch('/dishes', { refresh: 1000 * 60 * 10 })

  let [selection, setSelection] = useState(null)
  let addEntry = useSyncedEntries()
  let {
    direction,
    toggleDirection,
    startMotionUpdates,
    stats,
  } = useElevatorDirection({
    onChange: useMemo(
      () => direction => {
        if (direction === 'up') setSelection(null)
      },
      [],
    ),
    calibration,
    debug,
  })

  /**
   * Shows info about the selected plate, and pushes
   * the data to the server.
   */
  function handleSelectPlate({ dish, plate }) {
    setSelection({ dish, plate })
    addEntry({ dish, plate })
  }

  if (!data)
    return (
      <div className="h-full bg-gidas-light p-4 text-white">
        <p>Loading...</p>
      </div>
    )

  let dishes = data

  // Some platform requires motion listeners to be triggered on
  // user input, so we'll show a button here to start motion updates.
  if (startMotionUpdates) {
    return (
      <div className="h-full bg-gidas-light p-4 text-white flex justify-center items-start">
        <button
          className="my-4 rounded text-gray-900 shadow px-2 py-1 bg-gray-400 active:bg-gray-500"
          onClick={startMotionUpdates}
        >
          Start motion
        </button>
      </div>
    )
  }

  if (debug) {
    return (
      <div className="h-full bg-gidas-light p-4 text-white">
        <p className="font-bold">Acceleration</p>
        <p>Value: {stats.value}</p>
        <p>Sum: {stats.sum}</p>
        <p>Average: {stats.average}</p>
        <p>Median: {stats.median}</p>
      </div>
    )
  }

  return (
    <div className="min-h-full bg-gidas-light flex flex-col mobile-interaction">
      <AnimateSharedLayout>
        <motion.div
          key={
            direction === 'up'
              ? 'welcome'
              : selection === null
              ? 'select'
              : 'details'
          }
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
        >
          {direction === 'up' ? (
            <WelcomeScreen />
          ) : selection === null ? (
            <SelectionScreen
              dishes={dishes}
              handleSelectPlate={handleSelectPlate}
            />
          ) : (
            <DetailsScreen selection={selection} setSelection={setSelection} />
          )}
        </motion.div>
      </AnimateSharedLayout>
      <div className="mt-auto"></div>
      <GidasFooter onDoubleClick={toggleDirection} />
    </div>
  )
}

function GidasFooter({ onDoubleClick }) {
  return (
    <div className="flex flex-col items-center p-4 mb-8">
      <img
        onDoubleClick={onDoubleClick}
        className="w-24 h-24 drop-shadow mb-4"
        src={logo}
        alt="Gidås logo"
      />
      <p className="mb-1 text-lx text-white text-shadow-sm _font-semibold">
        Producerat av{' '}
        <span className="font-semibold">Gidås Hållbarhetsbyrå</span>
      </p>

      <p className="mb-1 text-lx text-white text-shadow-sm">
        hej@gidas.se - 070-685 59 39
      </p>
    </div>
  )
}

function WelcomeScreen() {
  return (
    <div className="text-white relative">
      <h1 className="text-5xl mt-20 mb-2 text-center font-bold font-overpass">
        Välkommen till Arctura!
      </h1>
      <h2 className="text-3xl mb-24 text-center font-overpass">
        En utbildande matupplevelse med hållbart fokus.
      </h2>
      <div className="flex flex-col items-center px-8">
        <div className="max-w-3xl grid gap-6 grid-cols-3 justify-between mb-4">
          <div
            style={{ gridColumn: 'span 3' }}
            className="flex items-center text-gray-700 bg-orange-100 shadow p-4 leading-relaxed"
          >
            <Fork className="h-24 m-2" />
            <div className="flex-1 text-2xl mx-4">
              <p className="mb-2">
                Tänk på hur mycket{' '}
                <span className="font-bold">mat du slänger idag</span> och hjälp
                Arctura nå målet att halvera matsvinnet.
              </p>
              <p>
                Kom tillbaka till skärmen efter besöket för att se matsvinnets{' '}
                <span className="font-bold">klimatavtryck</span>.
              </p>
            </div>
            <Knife className="h-24 mr-4" />
          </div>
          <img className="shadow" src={gri12} alt="Arcturas logo" />
          <img className="shadow" src={gri13} alt="Arcturas logo" />
          <img className="shadow" src={gri15} alt="Arcturas logo" />
        </div>
      </div>
    </div>
  )
}

function SelectionScreen({ dishes, handleSelectPlate }) {
  return (
    <>
      <h1 className="text-5xl mt-20 mb-12 text-center font-bold font-overpass text-white">
        {/* Hur mycket mat slängde du idag? */}
        Hur dåligt är det egentligen att slänga lite mat?
      </h1>
      <div className="px-8">
        <div className="max-w-3xl mx-auto">
          <div className="mb-8 bg-orange-100 shadow text-gray-800 py-4 px-8">
            <div className="flex">
              <div className="flex-1 text-2xl">
                <p className="mb-2">
                  Se matsvinnets{' '}
                  <span className="font-bold">klimatavtryck</span> här - tryck
                  på den tallrik som passar bäst in på dig idag!
                </p>
                <p>
                  Den{' '}
                  <span className="font-bold" style={{ color: '#f55d42' }}>
                    röda
                  </span>{' '}
                  biten motsvarar slängd mat.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-around">
            {dishes.map(dish => (
              <div
                className="shadow mb-8 flex-1 bg-gidas-dark p-8 flex flex-col text-white"
                key={dish.id}
              >
                <p className="text-2xl font-overpass">Om du åt</p>
                <p className="text-xl mb-4 -font-overpass">{dish.text}</p>
                <div className="flex space-x-8 items-center">
                  {[0, 1, 2, 3].map(i => (
                    <motion.div
                      key={i}
                      className="z-10 text-gray-200"
                      style={{ width: 100, height: 100 }}
                      layoutId={`${dish.id}-${i}`}
                      transition={spring}
                    >
                      <Plate
                        percent={i / 6}
                        onClick={() => handleSelectPlate({ dish, plate: i })}
                      />
                    </motion.div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

function DetailsScreen({ selection, setSelection }) {
  // This is the co2 impact of the given selection.
  let co2 = selection
    ? round(
        selection.dish.resources.reduce(
          (acc, curr) =>
            (selection.plate / 6) *
              (curr.pivot.weight ?? curr.portion_weight) *
              curr.co2 +
            acc,
          0,
        ) +
          SALAD_AND_BREAD_CO2_PER_PORTION * (selection.plate / 6),
        2,
      )
    : null

  return (
    <div className="px-8">
      <div className="max-w-3xl mx-auto">
        <div className="mt-16 flex flex-col md:flex-row items-center bg-gidas-dark shadow p-8 mb-8 text-white">
          <motion.div
            className="mr-8 mb-8 md:mb-0 relative z-10 text-gray-200"
            layoutId={`${selection.dish.id}-${selection.plate}`}
            style={{ width: 180, height: 180 }}
            transition={spring}
          >
            <Plate percent={selection.plate / 6} />
            {selection.plate === 0 ? (
              <div className="flex items-center justify-center text-5xl absolute inset-0">
                <span role="img" aria-label="Happy face">
                  🥳
                </span>
              </div>
            ) : null}
          </motion.div>
          <div className="flex-1">
            {co2 === 0 ? (
              <p className="text-xl">
                Toppen! Tack för att du hjälper oss
                <br />
                minska matsvinnet!
              </p>
            ) : (
              <>
                <h1 className="text-xl mb-8 font-overpass">
                  {selection.dish.text}
                </h1>
                <div className="flex space-x-8">
                  <div className="flex-1">
                    <p className="text-lg">
                      Det du slängt idag
                      <br />
                      motsvarar
                    </p>
                    <p className="text-5xl font-bold font-overpass leading-tight mt-2">
                      {co2} kg{' '}
                    </p>
                    <p className="text-lg">i koldioxidutsläpp</p>
                  </div>

                  <div className="flex-1">
                    <p className="text-lg">
                      Det är lika mycket som
                      <br />
                      att köra
                    </p>
                    <p className="text-5xl font-bold font-overpass leading-tight mt-2">
                      {diesel.value(co2)} km
                    </p>
                    <p className="text-lg">i dieselbil</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="bg-gidas-dark text-white text-lg shadow p-8 mb-8">
          {/* <p className="mb-2">
            Tänk efter när du tar mat - hjälp Arctura nå målet att halvera
            restaurangens matsvinn.
          </p> */}
          <p>Beräkningarna ovan baseras på en standardportion.</p>
        </div>
        <div className="flex justify-center mt-16">
          <button
            className="text-2xl text-white bg-gidas-dark bg-opacity-75 shadow-lg border-2 border-white active:bg-opacity-50 py-8 px-12"
            onClick={() => setSelection(null)}
          >
            Prova igen
          </button>
        </div>
      </div>
    </div>
  )
}

export default Kiosk
