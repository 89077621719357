import { useState, useEffect, useRef } from 'react'
import { api, post } from './Utilities'

/**
 * Fetch data from server and store it in local state.
 *
 * @param {string} url The url to fetch from.
 * @param {object} param1 refresh: Refresh interval
 */
export function useFetch(url, { refresh }) {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  let mounted = useRef(true)

  useEffect(() => {
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    async function fetchData() {
      try {
        let res = await api(url)
        let json = await res.json()
        if (!mounted.current) return
        setData(json)
      } catch (error) {
        if (!mounted.current) return
        setError(error)
      }
    }
    fetchData()

    let interval

    // We'll refresh if an interval is specified. Note that this
    // will reset if any dependencies to useEffect are changed.
    if (refresh) {
      interval = setInterval(fetchData, refresh)
      window.addEventListener('online', fetchData)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
      window.removeEventListener('online', fetchData)
    }
  }, [url, refresh])
  return { data, error }
}

/**
 * Provides a way to save waste entries, which will
 * eventually get synchronized to the server, once
 * we have Internet connection.
 */
export function useSyncedEntries() {
  let [entries, setEntries] = useState([])

  // Periodically sync all waste entries with server.
  useEffect(() => {
    async function fetchData() {
      if (entries.length) {
        try {
          let res = await post('/waste-entries', {
            entries: entries.map(x => ({
              dish_id: x.dish.id,
              fraction: x.plate / 6,
            })),
          })
          // We'll remove all saved entries on success.
          if (res.ok) {
            setEntries(e => e.filter(x => !entries.includes(x)))
          }
        } catch (e) {
          // Maybe no internet connection.
          // TODO The request might be successful but it we lose the connection
          // before we receive a response we will post the same resuls next
          // time, resulting in duplication. This will most likely not matter.
        }
      }
    }

    let interval = setInterval(fetchData, 5000)

    return () => clearInterval(interval)
  }, [entries])

  return ({ dish, plate }) => setEntries(x => x.concat({ dish, plate }))
}
