import React from 'react'

/**
 * Represents a plate, with colored slices - like a pie chart.
 *
 * @param {object} param0 percent: A percent value or an array of {percent, color}.
 */
export function Plate({ percent, onClick }) {
  let percentages = Array.isArray(percent)
    ? percent
    : [{ percent, color: '#f55d42' }]

  let outerRadius = 500
  let radius = 420

  return (
    <button onClick={onClick} className="drop-shadow w-full active:opacity-75">
      <svg viewBox="0 0 1000 1000">
        <circle
          r={outerRadius}
          cx={outerRadius}
          cy={outerRadius}
          fill="currentColor"
        />
        <circle r={radius} cx={outerRadius} cy={outerRadius} fill="white" />
        {percentages.map(({ percent, color }, i) => {
          if (!percent) return null

          let previousPercent = percentages
            .slice(0, i)
            .reduce((prev, { percent }) => prev + percent, 0)

          let rotation = -90 + 360 * previousPercent

          return (
            <circle
              key={i}
              r={radius / 2}
              cx={outerRadius}
              cy={outerRadius}
              fill="transparent"
              stroke={color}
              strokeWidth={radius}
              // dasharray is specified as "<fill> <gap>", as a percentage of the diameter.
              strokeDasharray={`${percent * radius * Math.PI} ${
                (1 - percent) * radius * Math.PI
              }`}
              transform={`rotate(${rotation}, ${outerRadius}, ${outerRadius})`}
            />
          )
        })}
        {/* {percentages.map(({ percent, label }, i) => {
          if (!percent || !label) return null

          let previousPercent = percentages
            .slice(0, i)
            .reduce((prev, { percent }) => prev + percent, 0)

          let fraction = previousPercent + percent / 2

          // The angle in radians is calculated here. We subtract
          // 90deg (PI / 2) since the plate slices start at (0, 1)
          // - the top of the plate.
          let angle = fraction * Math.PI * 2 - Math.PI / 2

          let x = Math.cos(angle) * (radius / 2)
          let y = Math.sin(angle) * (radius / 2)

          return (
            <text
              key={i}
              x={outerRadius + x}
              y={outerRadius + y}
              className="text-white fill-current font-bold font-overpass"
              fontSize="50"
              textAnchor="middle"
              dominantBaseline="middle"
            >
              {label}
            </text>
          )
        })} */}
      </svg>
    </button>
  )
}
