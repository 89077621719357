import React from 'react'
import arctura from '../img/arctura.png'
import dille from '../img/dille.png'
import logo from '../img/logo.png'
import classNames from 'classnames'

export function GidasBar({ wide = false }) {
  return (
    <div
      className="_shadow _bg-white text-gray-800"
      style={{ fontSize: '0.5rem' }}
    >
      <div className="flex">
        <div
          className={classNames({ 'flex-1': !wide })}
          style={{ minWidth: '1rem' }}
        ></div>
        <div
          className={classNames('flex flex-shrink-1', { 'flex-1': wide })}
          style={{ flexBasis: wide ? undefined : '48rem' }}
        >
          <div className="flex items-center py-1">
            <img className="w-6 h-6" src={logo} alt="Gidås logo" />
            <div>
              <p className="ml-4">
                Beräkningarna har gjorts och presenteras av Gidås
                Hållbarhetsbyrå
              </p>
              <p className="ml-4">hej@gidas.se, 070-685 59 39</p>
            </div>
          </div>
          <div className="ml-auto bottom-logo-bar bg-gidas-dark w-8"></div>
          <div className="flex flex-row justify-around items-center bg-gidas-dark py-1 pl-2">
            <div className="flex items-center justify-center h-6 w-6 mr:ml-auto md:mr-1">
              <img className="h-full" src={arctura} alt="Arcturas logo" />
            </div>
            <div className="flex items-center justify-center h-8 w-8">
              <img className="h-full" src={dille} alt="Dilles logo" />
            </div>
          </div>
        </div>
        <div
          className={classNames('bg-gidas-dark', { 'flex-1': !wide })}
          style={{ minWidth: '1rem' }}
        ></div>
      </div>
    </div>
  )
}
